import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Breadcrumb } from "react-bootstrap";

export default class ParallaxHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node.isRequired,
    paths: PropTypes.array.isRequired
  };

  render() {
    const { className, title, paths } = this.props;

    return (
      <header className={`parallax text-light text-uppercase ${className}`}>
        <Container className="h-100 d-flex flex-column justify-content-around">
          <div className="h-50 d-flex align-items-end justify-content-center">
            <h1 className="text-center">{title}</h1>
          </div>
          <div className="h-50 d-flex align-items-end justify-content-center">
            <Breadcrumb>
              {paths.map((item, index) => {
                if (index < paths.length - 1) {
                  return (
                    <li className="breadcrumb-item" key={index}>
                      <Link to={item.path}>{item.name}</Link>
                    </li>
                  );
                } else
                  return (
                    <Breadcrumb.Item key={index} active>
                      {item.name}
                    </Breadcrumb.Item>
                  );
              })}
            </Breadcrumb>
          </div>
        </Container>
      </header>
    );
  }
}
