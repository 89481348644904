import React, { Component } from "react";
import { Container } from "react-bootstrap";

export default class NotFoundPage extends Component {
  render() {
    return (
      <Container className="p-5 text-center">
        <h4>We are sorry but the content you are looking for was not found.</h4>
      </Container>
    );
  }
}
