import React, { Component } from "react";
import { Container } from "react-bootstrap";

export default class CookiePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <iframe
            id="acf81cc4-fdaf-431c-83dc-e1b7e2e56d7c"
            src="https://app.termly.io/embed/terms-of-use/acf81cc4-fdaf-431c-83dc-e1b7e2e56d7c"
            scrolling="no"
            allowFullScreen=""
            style={{
              height: 3171,
              opacity: 1,
              width: "100%",
              display: "block"
            }}
            frameBorder="0"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </Container>
      </React.Fragment>
    );
  }
}
