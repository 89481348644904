import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SubscriptionForm from "components/pages/home/SubscriptionForm";
import ventTwo from "img/home-vent-two.png";

export default class Subscribe extends Component {
  render() {
    const url =
      "//mrare.us8.list-manage.com/subscribe/post?u=77142ece814d3cff52058a51f&id=94d040322a";

    return (
      <section className="bg-primary text-white">
        <Container>
          <Row className="mb-5">
            <Col md="2" sm="1"></Col>
            <Col md="8" sm="10" className="text-center">
              <Image src={ventTwo} alt="Keep it locked" fluid />
              <h5 className="text-uppercase">
                Don't miss Exclusive updates from virun, Tips &amp; More
              </h5>
            </Col>
            <Col md="2" sm="1"></Col>
          </Row>
          <Row className="mt-5">
            <Col md="3" sm="1"></Col>
            <Col md="6" sm="10" className="text-center">
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <SubscriptionForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                  />
                )}
              />
            </Col>
            <Col md="3" sm="1"></Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center text-muted">
              * Newsletters are sent quarterly
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
