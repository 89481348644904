import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;

export function getLinks() {
  return new Promise((resolve, reject) => {
    const links = [];

    axios
      .get(apiUrl + "/link/press")
      .then(res => {
        links.push(res.data.link);

        axios
          .get(apiUrl + "/link/news")
          .then(news_res => {
            links.push(news_res.data.link);

            resolve(links);
          })
          .catch(err => {
            reject(err);
          });
      })
      .catch(err => {
        reject(err);
      });
  });
}
