import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";

import NavBar from "components/layout/NavBar";

import NotFoundPage from "components/pages/NotFoundPage";
import HomePage from "components/pages/home/HomePage";
import StartPage from "components/pages/start/StartPage";
import TechnologiesPage from "components/pages/TechnologiesPage";
import TechnologyPage from "components/pages/TechnologyPage";
import DevelopmentsPage from "components/pages/DevelopmentsPage";
import DevelopmentPage from "components/pages/DevelopmentPage";
import StorePage from "components/pages/store/StorePage";
import ProductPage from "components/pages/ProductPage";
import AboutPage from "components/pages/AboutPage";
import EventsPage from "components/pages/EventsPage";
import ContactPage from "components/pages/ContactPage";
import SitemapPage from "components/pages/SitemapPage";
import InternshipPage from "components/pages/InternshipPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";

import Footer from "components/layout/Footer";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import CookiePage from "./pages/CookiePage";

library.add(faTwitter, faFacebookF, faLinkedinIn, faYoutube, faInstagram);

class App extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <NavBar location={this.props.location.pathname} />

        <Switch>
          <Route path="/not-found" component={NotFoundPage} />
          <Route path="/cookie" component={CookiePage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/internship" component={InternshipPage} />
          <Route path="/sitemap" component={SitemapPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/events" component={EventsPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/product/:prod" component={ProductPage} />
          <Route path="/store" component={StorePage} />
          <Route path="/development/:dev" component={DevelopmentPage} />
          <Route path="/development" exact component={DevelopmentsPage} />
          <Route path="/technologies/:tech" component={TechnologyPage} />
          <Route path="/technologies" exact component={TechnologiesPage} />
          <Route path="/start" component={StartPage} />
          <Route path="/" exact component={HomePage} />
          <Redirect to="/not-found" />
        </Switch>

        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
