import React, { Component } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";

export default class Ingredients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredients: []
    };
  }

  componentDidMount() {
    if (this.state.ingredients.length === 0) {
      this.addIngredient();
    }
  }

  isValid(form) {
    const ingredient = form["ingredient1Name"];
    ingredient.setCustomValidity("Invalid");

    if (!form["ingredient1Name"].value) {
      return false;
    } else {
      ingredient.setCustomValidity("");

      return true;
    }
  }

  addIngredient() {
    this.setState({
      ingredients: [
        ...this.state.ingredients,
        this.state.ingredients.length + 1
      ]
    });
  }

  render() {
    let msg;

    if (this.state.ingredients.length === 0) {
      msg = (
        <div className="row text-center p-4">
          <div className="col">
            <p>
              No active ingredients added.
              <br />
              Press "Add Active Ingredient" to add an ingredient.
            </p>
          </div>
        </div>
      );
    }

    return (
      <Card id="ingredients" className="mt-5">
        <Card.Body>
          <Card.Title className="text-center pb-5">
            Active Ingredients
          </Card.Title>

          {msg}

          {this.state.ingredients.map(ingredient => (
            <Form.Row key={ingredient}>
              <Form.Group
                as={Col}
                sm="10"
                controlId={`ingredient${ingredient}Name`}
              >
                <Form.Label>Ingredient</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Ingredient"
                  name={`ingredient${ingredient}Name`}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                sm="2"
                controlId={`ingredient${ingredient}Weight`}
              >
                <Form.Label>Weight (mg)</Form.Label>
                <Form.Control
                  type="number"
                  defaultValue="10"
                  name={`ingredient${ingredient}Weight`}
                  min="0"
                />
              </Form.Group>
            </Form.Row>
          ))}

          <div className="d-flex justify-content-center">
            <Button
              className="px-5 py-2"
              variant="primary"
              size="sm"
              onClick={event => this.addIngredient()}
            >
              Add Active Ingredient
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
