import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Nav,
  Carousel,
  Button
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLinks } from "services/links";
import lightLogo from "img/light-logo.png";
import smAcne from "img/sm-acne.jpg";
import smTour from "img/sm-tour.jpg";
import smOmega3 from "img/sm-omega3.jpg";

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      press: "https://www.virun.com",
      news: "https://www.virun.com"
    };
  }

  componentDidMount() {
    getLinks().then(links => {
      if (links.length) {
        this.setState({ press: links[0], news: links[1] });
      }
    });
  }

  render() {
    const { press, news } = this.state;

    return (
      <footer className="bg-dark py-5 text-light">
        <Container>
          <Row>
            <Col lg="3" md="6" className="my-lg-0 my-5">
              <Image src={lightLogo} alt="Virun logo" fluid />
            </Col>
            <Col lg="3" md="6" className="my-lg-0 my-5">
              <h6 className="text-uppercase">Company</h6>
              <hr className="border border-secondary" />
              <Nav className="flex-column">
                <Nav.Link as={NavLink} to="/about">
                  About Us
                </Nav.Link>
                <Nav.Link as={NavLink} to="/store">
                  Virun Store
                </Nav.Link>
                <Nav.Link as={NavLink} to="/events">
                  Events
                </Nav.Link>
                <Nav.Link href={press} target="new" active={false}>
                  Press
                </Nav.Link>
                <Nav.Link as={NavLink} to="/contact">
                  Contact Us
                </Nav.Link>
              </Nav>
            </Col>
            <Col lg="3" md="6" className="my-lg-0 my-5">
              <h6 className="text-uppercase">Resources</h6>
              <hr className="border border-secondary" />
              <Nav className="flex-column">
                <Nav.Link href={news} target="new" active={false}>
                  News
                </Nav.Link>
                <Nav.Link as={NavLink} to="/sitemap">
                  Sitemap
                </Nav.Link>
                <Nav.Link as={NavLink} to="/internship">
                  Intership Program
                </Nav.Link>
              </Nav>
            </Col>
            <Col lg="3" md="6" className="my-lg-0 my-5">
              <h6 className="text-uppercase">Social Media</h6>
              <hr className="border border-secondary" />
              <Carousel indicators={false} fade={true}>
                <Carousel.Item>
                  <a
                    href="https://www.facebook.com/viruninnovations/"
                    target="new"
                  >
                    <img
                      className="d-block w-100"
                      src={smAcne}
                      alt="First slide"
                    />
                  </a>
                  <small>
                    Probiotics an answer to acne? Wish I knew that a couple of
                    decades ago...
                  </small>
                </Carousel.Item>
                <Carousel.Item>
                  <a
                    href="https://www.facebook.com/viruninnovations/"
                    target="new"
                  >
                    <img
                      className="d-block w-100"
                      src={smTour}
                      alt="First slide"
                    />
                  </a>
                  <small>
                    VIRUN finishes their Southern U.S.A. tour in Orlando, FL. at
                    #ingredientmarketplace Expo
                  </small>
                </Carousel.Item>
                <Carousel.Item>
                  <a
                    href="https://www.facebook.com/viruninnovations/"
                    target="new"
                  >
                    <img
                      className="d-block w-100"
                      src={smOmega3}
                      alt="First slide"
                    />
                  </a>
                  <small>
                    Omega 3 can halt the progress of liver damage. Hand me a
                    beer!
                  </small>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row className="mt-5 pt-3">
            <Col sm="6" className="text-muted">
              &copy; Copyright 2017 - Virun
            </Col>
            <Col sm="6" className="text-muted text-right">
              <a className="pr-2" href="https://twitter.com/virun" target="new">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              <a
                className="px-2"
                href="https://www.facebook.com/viruninnovations/"
                target="new"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </a>
              <a
                className="px-2"
                href="https://www.linkedin.com/company/virun"
                target="new"
              >
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </a>
              <a
                className="px-2"
                href="https://www.youtube.com/channel/UCwmhBzuJIDWrb36gm7kTJ7Q"
                target="new"
              >
                <FontAwesomeIcon icon={["fab", "youtube"]} />
              </a>
              <a
                className="pl-2"
                href="https://www.instagram.com/virun.nutrabiosciences/"
                target="new"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </Col>
          </Row>
          <Row className="mt-4 pt-4">
            <Col className="text-center">
              <Button
                size="sm"
                variant="outline-secondary"
                className="text-uppercase px-4"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                Top
              </Button>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
