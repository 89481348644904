import React from "react";

import productOne from "img/product1.jpg";
import productOneTwo from "img/product1-2.jpg";
import productTwo from "img/product2.png";
import productThree from "img/product3.jpg";

export const PRODUCTS = [
  {
    id: 0,
    images: [productOne, productOneTwo],
    brand: "Virun",
    item: "T-Shirt",
    price: 30.0,
    page: "",
    description:
      "Order one of our very popular Virun T-Shirts while supplies last. We currently carry our white virun shirt with a cool SXSW 2018 graphic on the back. 100% Cotton. ",
    properties: [
      {
        name: "Colors",
        values: ["White"]
      },
      {
        name: "Sizes",
        values: ["Women's M", "Unisex M", "Unisex L", "Unisex XL"]
      }
    ],
    createdAt: "2019-02-27 00:00:00",
    form: (
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="B8P6GTHJS63QJ" />
        <table>
          <tbody>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Sizes" />
                Sizes
              </td>
            </tr>
            <tr>
              <td>
                <select name="os0">
                  <option value="Women's M">Women's M $30.00 USD</option>
                  <option value="Unisex M">Unisex M $30.00 USD</option>
                  <option value="Unisex  L">Unisex L $30.00 USD</option>
                  <option value="Unisex  XL">Unisex XL $30.00 USD</option>
                </select>{" "}
              </td>
            </tr>
          </tbody>
        </table>
        <input type="hidden" name="currency_code" value="USD" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
          name="submit"
          alt="PayPal - The safer, easier way to pay online!"
          border="0"
        />
        <img
          alt=""
          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
          style={{ display: "none !important" }}
          width="1"
          hidden=""
          height="1"
          border="0"
        />
      </form>
    )
  },
  {
    id: 1,
    images: [productTwo],
    brand: "New Omega 3 Smoothies Now Available!",
    item: "Check out all of our delicious flavors",
    price: 0.0,
    page: "http://www.o3smoothies.com/",
    description: "",
    properties: [],
    createdAt: "2019-04-01 00:00:00",
    form: <React.Fragment></React.Fragment>
  },
  {
    id: 2,
    images: [productThree],
    brand: "Virun",
    item: "Stickers",
    price: 2.0,
    page: "",
    description:
      "Order Virun's sticker and show you're a Viruneer! Display it everywhere. ",
    properties: [
      {
        name: "Colors",
        values: ['4x6"']
      }
    ],
    createdAt: "2019-11-23 00:00:00",
    form: (
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="VCXECBB2U37G2" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
          name="submit"
          alt="PayPal - The safer, easier way to pay online!"
          border="0"
        />
        <img
          alt=""
          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
          style={{ display: "none !important" }}
          width="1"
          hidden=""
          height="1"
          border="0"
        />
      </form>
    )
  }
];
