import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Col, Card } from "react-bootstrap";

export default class ApplicationType extends Component {
  static propTypes = {
    imgSrc: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  render() {
    const { imgSrc, alt, onClick } = this.props;

    return (
      <Col xl="2" lg="3" md="4" sm="6" className="img-option p-1 m-0">
        <Form.Label>
          <Form.Check.Input
            type="radio"
            name="application"
            value={alt}
            onClick={onClick}
          />
          <Card>
            <Card.Img variant="top" src={imgSrc} alt={alt} />
            <Card.Footer className="text-center">{alt}</Card.Footer>
          </Card>
        </Form.Label>
      </Col>
    );
  }
}
