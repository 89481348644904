import React from "react";
import { Link } from "react-router-dom";
import astaxOne from "img/astax1.jpg";
import astaxTwo from "img/astax2.jpg";
import astaxThree from "img/astax3.jpg";
import dptechnologyOne from "img/dptechnology1.jpg";
import dptechnologyTwo from "img/dptechnology2.jpg";
import dptechnologyThree from "img/dptechnology3.jpg";
import esolvOne from "img/esolv1.jpg";
import esolvTwo from "img/esolv2.jpg";
import esolvThree from "img/esolv3.jpg";
import omegaOne from "img/omega1.jpg";
import omegaTwo from "img/omega2.jpg";
import omegaThree from "img/omega3.jpg";
import probiferrinOne from "img/probiferrin1.jpg";
import probiferrinTwo from "img/probiferrin2.jpg";
import probiferrinThree from "img/probiferrin3.jpg";

export const TECHNOLOGIES = {
  astaxh2o: {
    name: (
      <React.Fragment>
        AstaxH2O<sup>&trade;</sup>
      </React.Fragment>
    ),
    summary: (
      <React.Fragment>
        Virun’s AstaxH20<sup>&trade;</sup> can help you see your astaxanthin
        more clearly.
      </React.Fragment>
    ),
    sections: [
      {
        id: 0,
        image: astaxOne,
        title: (
          <React.Fragment>
            About AstaxH2O<sup>&trade;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            <strong>
              Why is Virun like a flamingo? Because it’s astaxanthin is
              beautiful, that's why.
            </strong>
            <br />
            <br />
            A flamingos’ feathers are those brilliant shades of pink, salmon and
            red because of the carotenoids found in the briny shrimp and the
            algae that makes up the majority of their diet. Astaxanthin is a
            naturally occurring carotenoid well known for its therapeutic
            benefits and antioxidant properties. It is pretty, but it is also
            stinky. Astaxanthin smells fishy, so it has typically been found in
            tablets and softgels, which removes the ingredient from much of
            consumers’ marketplace. Many people aren’t able to swallow capsules
            or just don’t wish to take yet another pill. Additionally,
            carotenoids are insoluble and difficult to get into the bloodstream.
            <br />
            <br />
            Virun’s AstaxH2O<sup>&trade;</sup> solves these problems. Using
            Virun’s patented
            <Link to="/technology/esolv">
              Esolv<sup>&copy;</sup>
            </Link>
            technology, the astaxanthin is deodorized through a bicarbonate
            process and encapsulated into micelles, and then spray-dried. The
            result is a water-soluble astaxanthin powder that is highly
            bioavailable, dissolves clear into beverages, and carries no fishy
            taste. See for yourself! The following video shows the dissolution
            of 4 mg. AstaxH2O to a 16 oz. beverage. It also acts as an
            absolutely beautiful natural color. (Click on the image of the glass
            below).
          </p>
        )
      },
      {
        id: 1,
        image: astaxTwo,
        title: "More About Astaxanthin",
        content: (
          <p>
            Astaxanthin is a naturally occurring carotenoid found in nature
            primarily in marine organisms such as microalgae, salmon, trout,
            krill, shrimp, crayfish, and crustaceans. The green
            <em>microalgae Haematococcus pluvialis</em> is considered the
            richest source of astaxanthin. Other microalgae, such as
            <em>Chlorella zofingiensis, Chlorococcum spp.</em>, and
            <em>Botryococcus braunii</em>, also contain astaxanthin. It may also
            be found in the feathers of birds, such as quail, flamingo, and
            storks, as well as in propolis, the resinous substance collected by
            bees.
          </p>
        )
      },
      {
        id: 2,
        image: astaxThree,
        title: "Carotenoids",
        content: (
          <p>
            Carotenoids are well known for their therapeutic benefits in the
            aging process and various diseases, because of their antioxidant
            properties. Astaxanthin is a xanthophyll carotenoid like lutein,
            zeaxanthin, and cryptoxanthin, which do not convert to vitamin A.
            <br />
            <br />
            According to a review published in the
            <em>Natural Medicine Journal,</em> carotenoids are of interest based
            on their beneficial mechanisms of action for cancers, cardiovascular
            disease, age-related macular degeneration, and cataract formation.
            Numerous studies support the use of astaxanthin as a potent
            antioxidant that may be beneficial in decreasing the risks of
            certain chronic diseases. It may also reduce oxidative stress in the
            nervous system, reducing the risk of neurodegenerative diseases.
            Additionally, astaxanthin has well-documented anti-inflammatory and
            immune-stimulating effects.
          </p>
        )
      }
    ],
    references: (
      <p>
        Natural Medicine Journal. Astaxanthin: A Review of the Literature; The
        scientific evidence on the uses, contraindications, and interactions of
        this carotenoid &copy; 2017 Natural Medicine Journal (ISSN 2157-6769)
      </p>
    )
  },
  dptechnology: {
    name: (
      <React.Fragment>
        DPtechnology<sup>&reg;</sup>
      </React.Fragment>
    ),
    summary: (
      <React.Fragment>
        Virun’s patented DPtechnology<sup>&reg;</sup> increases the
        pharmacokinetics of your product, by taking iron to a whole new level.
      </React.Fragment>
    ),
    sections: [
      {
        id: 0,
        image: dptechnologyOne,
        title: (
          <React.Fragment>
            About DPtechnology<sup>&reg;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            <strong>
              Cows pumping iron? Really? Yes, it makes sense, just hear me out.
            </strong>
            <br />
            <br />
            There are many ingredients out there that have amazing potential to
            change our health, but their efficacy is limited by their ability to
            get into our systems where they can do some good. Ingredients like
            resveratrol, turmeric, probiotics, glutathione, and etc., either
            have a hard time getting past stomach degradation (hence the concept
            of expensive urine), or can’t pass into the blood or lymphatic
            systems.
            <br />
            <br />
            That is where our Direct Precision Technology comes into play.
            Trademarked as DPtechnology<sup>&reg;</sup>, this patented delivery
            system combines principles of biology and chemistry as related to
            transferrins, to create a naturally occurring, timed delivery system
            for peptides, proteins and ingredient compounds. DPtechnology
            <sup>&reg;</sup> increases the bioavailability of ingredients while
            bypassing stomach degradation. This allows the better absorption,
            distribution, and metabolism of the bound ingredient.
          </p>
        )
      },
      {
        id: 1,
        image: dptechnologyTwo,
        title: (
          <React.Fragment>
            The Science-y Explanation of DPtechnology<sup>&reg;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            Lactoferrin has been shown to bypass stomach degradation and
            increases the survival rate of Proteins and Probiotics. Lactoferrin
            is an iron chelating protein. When not bound to iron, Lactoferrin
            chelates iron in the gut, binds lipo receptor proteins on the gut
            mucosa and mediates across the mucosa, thereby delivering iron into
            blood plasma. By binding, for example, proteins and probiotics to
            Lactoferrin, DPtechnology<sup>&reg;</sup> stabilizes the protein and
            probiotic and delivers proteins to blood plasma and probiotics to
            the gut mucosa surface.
            <br />
            <br />
            Virun uses its DPtechnology<sup>&reg;</sup> to successfully bind
            lactoferrin to probiotic strains causing the probiotics to become
            more durable. Virun has patented its probiotic delivery technology
            and trademarked it as
            <Link to="/technology/probiferrin">
              Probiferrin<sup>&trade;</sup>
            </Link>
            . Probiferrin has been shown to extend the shelf-life and stability
            of common probiotic strains at room temperature.
          </p>
        )
      },
      {
        id: 2,
        image: dptechnologyThree,
        title: (
          <React.Fragment>
            DPtechnology<sup>&reg;</sup> + Glutathione
          </React.Fragment>
        ),
        content: (
          <p>
            Virun’s DPtechnology<sup>&reg;</sup> is also used to bind
            lactoferrin to glutathione, making it stable in liquids used for
            beverages, shots, gels and smoothies. Glutathione is often called a
            “master antioxidant” as it has vast health benefits linked to the
            immune system, memory, eye health, energy and aging
            (WhatIsGlutathione.org, 2016). Outside factors such as stress and
            poor diet attribute to low levels of glutathione in the body, thus
            creating a need for supplementation.
            <br />
            <br />
            Contact <Link to="/contact">Virun</Link> to learn more about
            DPtechnology<sup>&reg;</sup> and how it can be applied to your
            ingredients and finished products.
          </p>
        )
      }
    ],
    references: (
      <p>
        Abramson, J. (2016, December 14). Endocytosis: Definition, Types &amp;
        Examples. Retrieved from Study.com:
        http://study.com/academy/lesson/endocytosis-definition-types-examples.html
        <br />
        Science Chemistry. (2016, December 14). Retrieved from IAC Publishing,
        LLC: https://www.reference.com/science/pk-chemistry-fd97258918ecc248#
        <br />
        Tian, H., Maddox, I., Ferguson, L., &amp; Shu, Q. (2010). Influence of
        bovine lactoferrin on selected probiotic bacteria and intestinal
        pathogens. Biometals, 593-6.
        <br />
        WhatIsGlutathione.org. (2016, December 14). Retrieved from What Is
        Glutathione: http://www.whatisglutathione.org/
        <br />
        Holland-Frei Cancer Medicine. 6th edition. Retrieved from https://
        www.ncbi.nlm.nih.gov/books/NBK12815
      </p>
    )
  },
  esolv: {
    name: (
      <React.Fragment>
        Esolv<sup>&reg;</sup>
      </React.Fragment>
    ),
    summary: (
      <React.Fragment>
        Virun’s patented Esolv<sup>&reg;</sup> technology allows you to follow
        your imagination when it comes to food, beverage and supplement
        applications.
      </React.Fragment>
    ),
    sections: [
      {
        id: 0,
        image: esolvOne,
        title: (
          <React.Fragment>
            About Esolv<sup>&reg;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            Esolv<sup>&reg;</sup> is a clean-label, patented technology
            exclusively developed by Virun. It is an emulsifier which uses
            micelles to encapusulate non-polar compounds and allows those
            compounds to dissolve easily into clear liquids. The effect is that
            ingredients using Esolv<sup>&reg;</sup> are more bio-available and
            allow better absorption by the body. Esolv<sup>&reg;</sup>
            is available in liquid, dry powder or bulk custom blends for a broad
            range of food, beverage, and supplement applications.
            <br />
            <br />
            Esolv<sup>&reg;</sup> is produced using natural vitamin E, and has
            been shown not to significantly absorb into the blood plasma of
            healthy adults, according to the European Food Safety Authority
            (EFSA).
          </p>
        )
      },
      {
        id: 1,
        image: esolvTwo,
        title: (
          <React.Fragment>
            The Science Behind Esolv<sup>&reg;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            Esolv<sup>&reg;</sup> is a patented, natural, Vitamin E emulsifier.
            Esolv<sup>&reg;</sup> is produced through polymerization, then
            naturally emulsified into water, which allows the Vitamin E to
            become water dispersible. Then, all free polymer is removed after
            processing, resulting in clean label Vitamin E as Alpha Tocopherol.
            Esolv<sup>&reg;</sup> is amphiphilic, which means it dissolves in
            both water and oil, and this character allows for the easy
            production of micelles after processing. The human body is incapable
            of absorbing water-insoluble nutrients unless these
            compounds/nutrients are first micellized. Esolv<sup>&reg;</sup>
            produces micelles that are as small as 10-20nm, which is the exact
            diameter of micelles your body requires for absorption of these
            nutrients.
            <br />
            <br />
            Combining Esolv<sup>&reg;</sup> with non-polar (water-insoluble)
            compounds such as Curcumin, CoQ10, Lutein, Lycopene, Beta Carotene
            and others, allows these compounds to absorb better and increase
            overall bioavailability, sometimes up to 10X more than without Esolv
            <sup>&reg;</sup>. One way you can test the absorption of a nutrient
            is to test its’ solubility in water; the more water soluble the
            nutrient is, the better it absorbs into the body. When Esolv
            <sup>&reg;</sup> products are dissolved into water, the liquid
            disperses evenly. This is exactly what happens when you supplement
            with Esolv<sup>&reg;</sup> products. The better it disolves, the
            better it is absorbed.
          </p>
        )
      },
      {
        id: 2,
        image: esolvThree,
        title: <React.Fragment>Some of the Products we make</React.Fragment>,
        content: (
          <p>
            Virun produces liquid and powder finished products and concentrates
            containing Esolv<sup>&reg;</sup>. Omega EPA DHA, Curcumin, CoQ10,
            PQQ, Phosphatidylserine, Lutein, Lycopene, Astaxanthin, Beta
            Carotene, Vitamins A, D, E and K are just an example of the many
            non-polar compounds we work with.
            <br />
            <br />
            <strong>
              Esolv<sup>&reg;</sup> liquids
            </strong>
            do not require heat, mixing or processing. Simply add Esolv
            <sup>&reg;</sup> liquid to any oil or water-insoluble ingredient and
            apply to your mixing process to create a functional food, beverage
            or supplement products.
            <br />
            <br />
            <strong>
              Esolv<sup>&reg;</sup> concentrates
            </strong>
            consist of stable, water soluble and clearer concentrates of
            ingredients such as Omega-3 EPA and DHA, CoQ10 or PQQ. Our clear,
            water soluble, shelf stable Esolv concentrates are used in
            functional waters, smoothies and other foods, beverages and
            supplement product.
            <br />
            <br />
            <strong>
              Esolv<sup>&reg;</sup> powder
            </strong>
            technologies combine Virun’s patented technology with clean label
            claims into a stable, water soluble, and high oil load powders that
            are easily added into products such as ready-to-drink (RTD)
            beverages, shots, stick packs and dry mixes.
          </p>
        )
      }
    ]
  },
  omegah2o: {
    name: (
      <React.Fragment>
        OmegaH2O<sup>&reg;</sup>
      </React.Fragment>
    ),
    summary: (
      <React.Fragment>
        Wouldn’t it be nice to take an Omega supplement that doesn’t come in a
        pill, or taste like cat food? Now you can.
      </React.Fragment>
    ),
    sections: [
      {
        id: 0,
        image: omegaOne,
        title: (
          <React.Fragment>
            About OmegaH2O<sup>&reg;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            According to the University of Maryland Medical Center, research
            shows that omega-3 fatty acids reduce inflammation and may help
            lower risk of chronic diseases such as heart disease, cancer, and
            arthritis. Omega-3 fatty acids are highly concentrated in the brain
            and appear to be important for cognitive (brain memory and
            performance) and behavioral function. In fact, infants who do not
            get enough omega-3 fatty acids from their mothers during pregnancy
            are at risk for developing vision and nerve problems. Symptoms of
            omega-3 fatty acid deficiency include fatigue, poor memory, dry
            skin, heart problems, mood swings or depression, and poor
            circulation<sup>A</sup>.
            <br />
            <br />
            So, let’s face it. We all know Omegas are a big business. But we
            also know that only the cat likes the smell and taste of Omega oil.
          </p>
        )
      },
      {
        id: 1,
        image: omegaTwo,
        title: (
          <React.Fragment>
            OmegaH20<sup>&reg;</sup> is different
          </React.Fragment>
        ),
        content: (
          <p>
            OmegaH2O<sup>&reg;</sup> is a product platform which utilizes 40
            different patented and patents-pending technologies. Omega Oil is
            deodorized and deoxidized using a proprietary bicarbonate +HC03
            process, then encapsulated using Virun’s patented
            <Link to="/technology/esolv">
              eSolv<sup>&reg;</sup>
            </Link>
            technology. This process preserves the Omega-3 EPA DHA and prevents
            spoiling and oxidation in finished product applications. Hence, all
            the benefit, none of the stink. We use this process to create high
            oil-load EPA DHA smoothies, clear EPA DHA functional waters, and
            high-load vegetarian powders. Our applications have up to a two-year
            shelf life at room temperature, high pH and low moisture to keep the
            Omega-3 stable and better tasting.
          </p>
        )
      },
      {
        id: 2,
        image: omegaThree,
        title: <React.Fragment></React.Fragment>,
        content: (
          <React.Fragment>
            <p>
              <strong>Our capabilities</strong>
            </p>
            <ul className="bullets mb-xs-24">
              <li>Up to 100 mg Omega-3 EPA DHA in clear beverages</li>
              <li>
                Up to 3000 mg Omega-3 EPA DHA in 16 oz. beverages and smoothies{" "}
              </li>
              <li>Up to 3000 Omega-3 EPA DHA in a 2 oz. shot</li>
              <li>
                Can be combined with protein and up to 2000 milligrams MCT
                (Medium Chain Triglycerides)
              </li>
              <li>Up to two years shelf stable</li>
              <li>We also have options for vegetarian emulsions and powders</li>
            </ul>
            <p>
              <strong>Wide variety of flavors available</strong> (or allow us to
              develop a new flavor for you)
            </p>
            <ul className="bullets mb-xs-24">
              <li>Berry</li>
              <li>Cherry Vanilla </li>
              <li>Key Lime</li>
              <li>Coconut Pineapple</li>
              <li>Orange Creamsicle</li>
              <li>Red Apple Caramel</li>
              <li>Old Fashion Waffles &amp; Maple Syrup</li>
              <li>Movie Theatre Buttered Popcorn</li>
              <li>Pina Colada </li>
              <li>Banana Split </li>
            </ul>
            <p>
              In the end, it’s completely up to you, but...would you rather your
              product taste like the image on the left or right?
            </p>
          </React.Fragment>
        )
      }
    ],
    references: (
      <p>
        A. Omega 3 Fatty Acids.
        http://umm.edu/health/medical/altmed/supplement/omega3-fatty-acids
      </p>
    )
  },
  probiferrin: {
    name: (
      <React.Fragment>
        Probiferrin<sup>&trade;</sup>
      </React.Fragment>
    ),
    summary: (
      <React.Fragment>
        So, you want to build a probiotic that actually stands up to its claims?
        You’ve come to the right place.
      </React.Fragment>
    ),
    sections: [
      {
        id: 0,
        image: probiferrinOne,
        title: (
          <React.Fragment>
            About Probiferrin<sup>&trade;</sup>
          </React.Fragment>
        ),
        content: (
          <p>
            We all know that probiotics are beneficial for health. Store shelves
            are bursting with products fighting over shelf space and market
            share. Yogurt, Greek yogurt, light yogurt, non-fat yogurt, flavored
            yogurt, non-flavored yogurt, kefir, kombucha, kimchi, probiotics
            found in liquids, capsules, tablets...is your head spinning yet? I
            know mine is.
            <br />
            <br />
            There are innumerable probiotic products out there. It’s confusing.
            All claim some benefit. But guess what? Not all these products are
            created equal. Some contain only a fraction of the bacteria
            necessary to actually promote benefits like immune and digestive
            health. And, while some products contain lots of these wonderful
            little bugs, many of them are ineffective. Why? Because they may be
            dead before ever reaching the consumer’s digestive system. It’s not
            just ineffective. It’s gross.
            <br />
            <br />
            Probiotics are fragile. If their environment contains less than
            ideal conditions of heat, moisture, and Ph, they may die and cause
            your product to be ineffective, or alternatively germinate too soon
            and cause your product to spoil. And, even if the bacteria live long
            enough to endure the manufacturing process and time spent on the
            shelf, many will not survive the harsh conditions of the human
            digestive system.
          </p>
        )
      },
      {
        id: 1,
        image: probiferrinTwo,
        title: (
          <React.Fragment>
            Enter Probiferrin<sup>&trade;</sup>
          </React.Fragment>
        ),
        content: (
          <React.Fragment>
            <p>
              Probiferrin<sup>&trade;</sup> is a patented probiotic technology
              exclusively developed by Virun. It utilizes Virun’s
              <Link to="/technology/dptechnology">
                DPtechnology<sup>&trade;</sup>
              </Link>
              to bind lactoferrin to probiotic strains. This binding process
              makes probiotics more durable, and increases their survivability
              in the gut and in the shelf life of both ambient and refrigerated
              products. <br />
              <br />
              Why Probiferrin<sup>&trade;</sup> can help your probiotic product
              survive: Lactoferrin is a naturally occurring protein found in the
              body. It is necessary for immune function and iron absorption.
              And, it turns out, lactoferrin is good for probiotics. According
              to a recent study, bacteria and probiotics depend on iron to
              colonize (Deschemin, et al., 2015). Additionally, lactoferrin has
              been shown to reduce the number of bad bacteria in the gut and
              assist in the survival of good bacteria (probiotics) (Tian,
              Maddox, Ferguson, &amp; Shu, 2010) (Sherman, Bennett, Hwang, &amp;
              Yu, 2004). Formulating your probiotic product using Virun’s
              Probiferrin<sup>&trade;</sup> will increase the efficacy and
              stability of your finished product. Probiotic strains that
              Probiferrin can be applied to are:
            </p>
            <br />
            <ul className="bullets mb-xs-24">
              <li>
                <em>Bifidobacterium lactis</em>
              </li>
              <li>
                <em>Bifidobacterium infantis</em>
              </li>
              <li>
                <em>Lactobacillus acidophilus</em>
              </li>
              <li>
                <em>Lactobacillus rhamnosus</em>
              </li>
            </ul>
          </React.Fragment>
        )
      },
      {
        id: 2,
        image: probiferrinThree,
        title: <React.Fragment>More About Probiotics</React.Fragment>,
        content: (
          <p>
            Probiotics are living microorganisms that, when consumed, have the
            potential to confer a beneficial health effect (Degnan, 2016).
            Probiotics are fragile bacteria that traditionally are in fermented
            food such as yogurt. Digestive and immune health benefits are linked
            to these healthy bugs. Studies show 70% of the immune system is
            found in the gut (Vighi, Marcucci, Sensi, Di Cara, &amp; Frati,
            2008) and probiotics are vital for keeping the gut healthy. However,
            lifestyle factors such as poor diet, stress, travel and antibiotic
            resistance attribute to probiotic deficiencies inside the body.
            <br />
            <br />
            Probiotics are fragile bacteria and manufacturers need to be aware
            of the ideal conditions of heat, moisture and pH of products, to
            prevent the premature germination of the bacteria. Otherwise it may
            cause the finished product to spoil and the packaging to bulge.
            Manufacturers want to have their probiotics in a dormant state until
            they hit their intended target in the gut. Heat, moisture and pH in
            the gut are ideal conditions for probiotics to grow and allow for
            individuals to receive optimal health benefits.
          </p>
        )
      }
    ],
    references: (
      <p>
        Degnan, F. H. (2016, December 8). The US Food and Drug Administration
        and Probiotics: Regulatory Categorization. Retrieved from Oxford
        Journals:
        http://cid.oxfordjournals.org/content/46/Supplement_2/S133.full
        <br />
        Deschemin, J.-C., Noordine, M.-L., Remot, A., Willemetz, A., Afif, C.,
        Canonne-Hergaux, F., . . . Nicolas, G. (2015). The microbiota shifts the
        iron sensing of intestinal cells. The FASEB Journal, 252-261.
        <br />
        Sherman, M., Bennett, S., Hwang, F., &amp; Yu, C. (2004). Neonatal small
        bowel epithelia: enhancing antibacterial defense with lactoferrin and
        Lactobacillus GG. Biometals, 285-9.
        <br />
        Tian, H., Maddox, I., Ferguson, L., &amp; Shu, Q. (2010). Influence of
        bovine lactoferrin on selected probiotic bacteria and intestinal
        pathogens. Biometals, 593-596.
        <br />
        Vighi, G., Marcucci, F., Sensi, L., Di Cara, G., &amp; Frati, F. (2008).
        Allergy and the gastrointestinal system. Clinical and Experimental
        Immunology 153 (Suppl. 1), 3-6.
      </p>
    )
  }
};
