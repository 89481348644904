import React, { Component } from "react";
import { Container } from "react-bootstrap";

export default class TermsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <iframe
            id="ae912a3c-0074-4de9-a0f3-4e38c087051f"
            src="https://app.termly.io/embed/terms-of-use/ae912a3c-0074-4de9-a0f3-4e38c087051f"
            scrolling="no"
            allowFullScreen=""
            style={{
              height: 7684,
              opacity: 1,
              width: "100%",
              display: "block"
            }}
            frameBorder="0"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </Container>
      </React.Fragment>
    );
  }
}
