import React, { Component } from "react";
import { Container } from "react-bootstrap";

export default class PrivacyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <iframe
            id="6a48b8e0-fe0b-4cd0-abfe-4a11b13b9f85"
            src="https://app.termly.io/embed/terms-of-use/6a48b8e0-fe0b-4cd0-abfe-4a11b13b9f85"
            scrolling="no"
            allowFullScreen=""
            style={{
              height: 5735,
              opacity: 1,
              width: "100%",
              display: "block"
            }}
            frameBorder="0"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </Container>
      </React.Fragment>
    );
  }
}
