import React, { Component } from "react";

import RegularHeader from "components/layout/RegularHeader";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { createMessage } from "services/messages";

export default class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      result: {
        show: false,
        title: "",
        body: "",
        button: ""
      }
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ validated: true });

    if (event.target.checkValidity() === false) {
      event.stopPropagation();
    } else {
      createMessage(new FormData(event.target))
        .then(res => {
          if (res) {
            this.setState({
              result: {
                show: true,
                title: "Thank you for your message.",
                body:
                  "Thank you for your message, we will get back to you as soon as we can.",
                button: "Return to main page"
              }
            });
          } else {
            this.setState({
              result: {
                show: true,
                title: "Sorry could not process your message.",
                body:
                  "Sorry we could not process your message, please try again later.",
                button: "Retry"
              }
            });
          }
        })
        .catch(err => {
          this.setState({
            result: {
              show: true,
              title: "Sorry could not process your message.",
              body:
                "Sorry we could not process your message, please try again later.",
              button: "Retry"
            }
          });
        });
    }
  }

  handleResult() {
    if (this.state.result.button === "Retry") {
      this.setState({
        result: {
          show: false,
          title: "",
          body: "",
          button: ""
        }
      });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    const { validated, result } = this.state;

    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Contact Us", path: "" }
          ]}
        />

        <section className="parallax parallax-nav contact"></section>
        <section>
          <Container>
            <Row>
              <Col md="6">
                <h4 className="text-uppercase mb-5">Get In Touch</h4>
                <p>
                  Have a question or want to learn more about what we do? <br />
                  Get in touch. We'd love to hear from you.
                </p>
                <hr />
                <p>
                  2160 S. Reservoir Street
                  <br /> Pomona, CA 91766
                </p>
                <hr />
                <p>
                  <strong>E:</strong> innovate.today@virun.com
                  <br />
                  <strong>P:</strong> 909.839.0629
                </p>
              </Col>
              <Col md="6">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={event => this.handleSubmit(event)}
                >
                  <Row className="mb-4">
                    <Col>
                      <Form.Control
                        name="fullname"
                        size="lg"
                        placeholder="FULL NAME"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide your full name.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Form.Control
                        name="email"
                        type="email"
                        size="lg"
                        placeholder="EMAIL ADDRESS"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide an email.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Form.Control
                        name="content"
                        as="textarea"
                        rows="4"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please type your message.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Button
                        type="submit"
                        variant="primary"
                        block
                        className="p-3"
                      >
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>

        <Modal show={result.show} centered backdrop="static">
          <Modal.Header>
            <Modal.Title>{result.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{result.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={event => this.handleResult()}>
              {result.button}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
