import React, { Component } from "react";
import { Card, Form, Col } from "react-bootstrap";

export default class Regulations extends Component {
  isValid(form) {
    return true;
  }

  render() {
    return (
      <Card id="regulations" className="mt-5">
        <Card.Body>
          <Card.Title className="text-center pb-5">
            Regulatory and Specific Requirements
          </Card.Title>

          <Form.Row>
            <Form.Group as={Col} sm="6" controlId="kosher">
              <Form.Check
                type="checkbox"
                label="Kosher"
                name="regulations[]"
                value="Kosher"
              />
            </Form.Group>
            <Form.Group as={Col} sm="6" controlId="organic">
              <Form.Check
                type="checkbox"
                label="Organic"
                name="regulations[]"
                value="Organic"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="6" controlId="nonGMO">
              <Form.Check
                type="checkbox"
                label="Non-GMO"
                name="regulations[]"
                value="Non-GMO"
              />
            </Form.Group>
            <Form.Group as={Col} sm="6" controlId="glutenFree">
              <Form.Check
                type="checkbox"
                label="Gluten Free"
                name="regulations[]"
                value="Gluten Free"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="6" controlId="dairyFree">
              <Form.Check
                type="checkbox"
                label="Dairy Free"
                name="regulations[]"
                value="Dairy Free"
              />
            </Form.Group>
            <Form.Group as={Col} sm="6" controlId="Vegan">
              <Form.Check type="checkbox" label="Vegan" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="6" controlId="noPreservatives">
              <Form.Check
                type="checkbox"
                label="No Preservatives"
                name="regulations[]"
                value="No Preservatives"
              />
            </Form.Group>
            <Form.Group as={Col} sm="6" controlId="noPeanuts">
              <Form.Check
                type="checkbox"
                label="No Peanuts"
                name="regulations[]"
                value="No Peanuts"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="6" controlId="noTreeNuts">
              <Form.Check
                type="checkbox"
                label="No Tree Nuts"
                name="regulations[]"
                value="No Tree Nuts"
              />
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
    );
  }
}
