import beverage from "img/beverage.jpg";
import shot from "img/shot.jpg";
import multiServingLiquid from "img/multi-serving-liquid.jpg";
import multiServingSmoothie from "img/multi-serving-smoothie.jpg";
import multiServingCreamers from "img/multi-serving-creamers.png";
import liquidPouches from "img/liquid-pouches.jpg";
import gummies from "img/gummies.jpg";
import bars from "img/bars.jpg";
import softGel from "img/soft-gel.jpg";
import tinctures from "img/tinctures.jpg";
import syringeOral from "img/syringe-oral.jpg";
import stickPackPowder from "img/stick-pack-powder.png";
import pouchPowder from "img/pouch-powder.jpg";
import tubPowder from "img/tub-powder.jpg";

export const APPLICATIONS_TYPE_LIST = [
  {
    id: "formGridBeverage",
    imgSrc: beverage,
    alt: "Beverage"
  },
  {
    id: "formGridShot",
    imgSrc: shot,
    alt: "Shot"
  },
  {
    id: "formGridMultiServingLiquid",
    imgSrc: multiServingLiquid,
    alt: "Multi-Serving Liquid"
  },
  {
    id: "formGridMultiServingSmoothie",
    imgSrc: multiServingSmoothie,
    alt: "Multi-Serving Smoothie"
  },
  {
    id: "formGridMultiServingCreamers",
    imgSrc: multiServingCreamers,
    alt: "Multi-Serving Creamers"
  },
  {
    id: "formGridLiquidPouches",
    imgSrc: liquidPouches,
    alt: "Liquid Pouches"
  },
  {
    id: "formGridGummies",
    imgSrc: gummies,
    alt: "Gummies"
  },
  {
    id: "formGridBars",
    imgSrc: bars,
    alt: "Bars"
  },
  {
    id: "formGridSoftGelCapsule",
    imgSrc: softGel,
    alt: "Soft Gel Capsule"
  },
  {
    id: "formGridTinctures",
    imgSrc: tinctures,
    alt: "Tinctures"
  },
  {
    id: "formGridSyringeOral",
    imgSrc: syringeOral,
    alt: "Syringe Oral"
  },
  {
    id: "formGridStickPackPowder",
    imgSrc: stickPackPowder,
    alt: "Stick Pack Powder"
  },
  {
    id: "formGridPouchPowder",
    imgSrc: pouchPowder,
    alt: "Pouch Powder"
  },
  {
    id: "formGridTubPowder",
    imgSrc: tubPowder,
    alt: "Tub Powder"
  }
];
