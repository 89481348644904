import React, { Component } from "react";
import { Card, Form, Col } from "react-bootstrap";

export default class Packaging extends Component {
  isValid(form) {
    return true;
  }

  render() {
    return (
      <Card id="packaging" className="mt-5">
        <Card.Body>
          <Card.Title className="text-center pb-5">Choose Packaging</Card.Title>

          <Form.Row>
            <Form.Group as={Col} sm="4" xs="12" controlId="packageSize">
              <Form.Label>Package Size</Form.Label>
              <Form.Control
                type="number"
                defaultValue="2"
                name="packageSize"
                min="1"
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} sm="4" xs="12" controlId="servingSize">
              <Form.Label>Serving Size</Form.Label>
              <Form.Control
                type="number"
                defaultValue="1"
                name="servingSize"
                min="1"
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} sm="4" xs="12" controlId="servingsPerPackage">
              <Form.Label>Servings Per Package</Form.Label>
              <Form.Control
                type="number"
                defaultValue="4"
                name="servingsPerPackage"
                min="1"
              ></Form.Control>
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
    );
  }
}
