import React, { Component } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { APPLICATIONS_TYPE_LIST } from "components/pages/start/applications";

import ApplicationType from "components/pages/start/ApplicationType";

export default class ApplicationTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      pH: false
    };
  }

  isValid(form) {
    const application = form["application"];

    if (this.state.pH) {
      const pH = form["pH"];

      pH.setCustomValidity("Invalid");

      if (pH.value > 4.3) {
        this.setState({
          error: "Sorry but we cannot process an application with such high pH."
        });

        return false;
      }

      pH.setCustomValidity("");
    }

    if (!application.value) {
      this.setState({ error: "Please choose an application." });

      return false;
    } else {
      this.setState({ error: "" });

      return true;
    }
  }

  checkPH(event) {
    const { value } = event.target;

    if (value === "Beverage" || value === "Shot") {
      this.setState({ pH: true });
    } else {
      this.setState({ pH: false });
    }
  }

  render() {
    const { error, pH } = this.state;
    return (
      <Card id="application" className="mt-5">
        <Card.Body>
          <Card.Title className="text-center pb-5">
            Type Of Application
          </Card.Title>

          <Row>
            {APPLICATIONS_TYPE_LIST.map(app => (
              <ApplicationType
                key={app.id}
                imgSrc={app.imgSrc}
                alt={app.alt}
                onClick={event => this.checkPH(event)}
              />
            ))}
          </Row>
          {pH && (
            <Row className="mt-5 text-center">
              <Col md="4"></Col>
              <Col md="3">
                <Form.Label htmlFor="pH">
                  What is the pH of your application?
                </Form.Label>
              </Col>
              <Col md="2">
                <Form.Control
                  type="number"
                  defaultValue="0"
                  min="0"
                  step="0.1"
                  name="pH"
                  id="pH"
                />
              </Col>
              <Col md="3"></Col>
            </Row>
          )}
          {error && (
            <Row>
              <Col>
                <p className="text-danger text-center">{error}</p>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }
}
