import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "components/utils/ScrollToTop";
import App from "components/App";

import config from "./config";

import "styles/main.scss";

ReactDOM.render(
  <BrowserRouter basename={config.basePath}>
    <ScrollToTop />

    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
