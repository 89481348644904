import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;

export function createMessage(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/messages/create", data)
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}
