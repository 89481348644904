import React, { Component } from "react";
import { Container } from "react-bootstrap";

import RegularHeader from "components/layout/RegularHeader";
import DevelopmentList from "components/pages/shared/DevelopmentList";

export default class TechnologiesPage extends Component {
  render() {
    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Product Development", path: "" }
          ]}
        />

        <Container className="my-4">
          <DevelopmentList />
        </Container>
      </React.Fragment>
    );
  }
}
