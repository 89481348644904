import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import ParallaxHeader from "components/layout/ParallaxHeader";

import teamOne from "img/team-1.jpg";
import teamTwo from "img/team-2.jpg";
import teamThree from "img/team-3.jpg";
import teamFour from "img/team-4.jpg";
import teamFive from "img/team-5.jpg";

export default class AboutPage extends Component {
  render() {
    return (
      <React.Fragment>
        <ParallaxHeader
          className={`parallax-nav about`}
          title="About Us"
          paths={[
            { name: "Home", path: "/" },
            { name: "About Us", path: "/about" }
          ]}
        />

        <Container>
          <Row className="my-5 py-5">
            <Col md="3">
              <h5 className="text-uppercase">
                <strong>Our Start</strong>
              </h5>
            </Col>
            <Col md="9">
              <p>
                Once upon a time, there was a really creative, dreadlock wearing
                Southern California surfer-dude that was skilled in biology and
                chemistry. His name was Philip Bromley, and he worked as a
                formulator. One day, the surfer-dude met Nick Huang,
                international espionage expert and the legendary James Bond of
                China (okay, we might be lying about that part). Nick was an
                ingredients supplier with an international network at his
                fingertips. The two quickly realized they could use Philip’s
                formulation experience and Nick’s contacts to launch a new
                company, specializing in unique delivery technologies for the
                medical industry. The technology Philip and Nick developed was
                called Mucosal Adhesive Penetrating Technology (MAPT), a drug
                delivery system designed to improve the delivery of insulin
                orally while avoiding the use of injections. Philip and Nick
                patented the technology, and its early success paved the way for
                the fledgling company to expand into other delivery systems.
              </p>
            </Col>
          </Row>
        </Container>
        <hr className="mb-0" />
        <Container>
          <Row className="my-5 py-5">
            <Col md="3">
              <h5 className="text-uppercase">
                <strong>Our Name</strong>
              </h5>
            </Col>
            <Col md="9">
              <p>
                Our name hearkens back to Philip and Nick’s early ties to the
                pharmaceutical industry. Philip’s delivery technologies were
                closely based upon the natural processes seen in viruses and the
                immune system. So, when it came time to christen Philip and
                Nick’s brainchild, the name of the new company was an obvious
                choice: Virus + Immune = Virun. Voila! Virun was born.
              </p>
            </Col>
          </Row>
        </Container>
        <hr className="mb-0" />
        <Container>
          <Row className="my-5 py-5">
            <Col md="3">
              <h5 className="text-uppercase">
                <strong>Where We Are Now</strong>
              </h5>
            </Col>
            <Col md="9">
              <p>
                Fast forward to 2017. Philip still surfs, but no longer wears
                dreadlocks, and the hair he has left is an ever-changing rainbow
                of blues, greens and reds. Nick, however, hasn’t changed; he
                still considers himself the James Bond of China. And Virun has
                branched into the functional foods and beverages market. Over
                the past decade or two, this industry has exploded, despite a
                lack of innovative technologies and truly functional products.
                But this environment is where Virun has excelled. Seeing an
                important correlation between drug delivery and nutrient
                delivery, Philip was able to apply the principles of biology and
                chemistry to functional foods, beverages and supplements. As a
                result, the company now holds more than 80 patents and patents
                pending worldwide, and is the only company capable of creating
                truly functional and innovative formulations and products for
                the food, beverage, and supplement industries. Not only does
                Virun specialize in oil emulsions for ingredients such as
                Omega-3 EPA/DHA, astaxanthin, and PQQ, but it recently developed
                a new delivery technology for probiotics, which increases
                probiotic survivability and shelf-life.
                <br />
                <br />
                The company continues to invest in and create technologies that
                change the way people receive nutritious ingredients.
              </p>
            </Col>
          </Row>
        </Container>
        <hr className="mb-0" />
        <Container>
          <Row className="my-5 py-5">
            <Col md="3">
              <h5 className="text-uppercase">
                <strong>Our Mission</strong>
              </h5>
            </Col>
            <Col md="9">
              <p>
                Virun’s mission is to make nutrition better by applying
                NutraBiosciences®, a unique platform which combines the
                principles of chemistry and biology to create new delivery
                technologies for healthy but difficult-to-formulate ingredients
                and incorporate them into nourishing finished products. Our
                company specializes in formulating and co-manufacturing novel
                nutritional products which truly look to improving health and
                wellbeing, instead of simply appearing to do so.
              </p>
            </Col>
          </Row>
        </Container>
        <hr className="mb-0" />
        <Container>
          <Row className="my-5 py-5">
            <Col md="3">
              <h5 className="text-uppercase">
                <strong>Our Presence in the Marketplace</strong>
              </h5>
            </Col>
            <Col md="9">
              <p>
                In the United States, our technologies and finished products are
                sold in Whole Foods, Kroger’s, Sprout’s, Erewhon, and other
                major retailers, warehouse clubs and online retailers
                nationwide. We also have a strong presence in international
                markets and are growing every day. Products made by Virun
                include Omega and MCT smoothies and waters, clear water-type
                beverages and teas, orange and ginger sodas, beers, probiotic
                products, milk products, vitamin gummies, glutathione
                supplements, powdered coffee creamers, numerous vitamin
                supplements, weight loss products, energy drinks, protein drinks
                and smoothies, and many more.
              </p>
            </Col>
          </Row>
        </Container>
        <hr className="mb-0" />
        <Container>
          <Row className="my-5 py-5">
            <Col md="3" className="text-center pb-5">
              <h5 className="text-uppercase">
                <strong>Meet the Team</strong>
              </h5>
            </Col>
            <Col md="9">
              <Row className="mb-5 pb-5">
                <Col md="6">
                  <Image src={teamOne} alt="Tyler Stowater" fluid />
                </Col>
                <Col md="6" className="text-center">
                  <h5 className="text-uppercase">
                    <strong>Tyler Stowater</strong>
                  </h5>
                  <span className="inline-block">Board Director</span>
                </Col>
              </Row>
              <Row className="my-5 py-5">
                <Col md="6">
                  <Image src={teamTwo} alt="Rick Crandall" fluid />
                </Col>
                <Col md="6" className="text-center">
                  <h5 className="text-uppercase">
                    <strong>Rick Crandall</strong>
                  </h5>
                  <span className="inline-block">Board Director</span>
                </Col>
              </Row>
              <Row className="my-5 py-5">
                <Col md="6">
                  <Image src={teamThree} alt="Jack Owoc" fluid />
                </Col>
                <Col md="6" className="text-center">
                  <h5 className="text-uppercase">
                    <strong>Jack Owoc</strong>
                  </h5>
                  <span className="inline-block">Board Director</span>
                </Col>
              </Row>
              <Row className="my-5 py-5">
                <Col md="6">
                  <Image src={teamFour} alt="Nickols Huang" fluid />
                </Col>
                <Col md="6" className="text-center">
                  <h5 className="text-uppercase">
                    <strong>Nickols Huang</strong>
                  </h5>
                  <span className="inline-block">President</span>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Image src={teamFive} alt="Philip J. Bromley" fluid />
                </Col>
                <Col md="6" className="text-center">
                  <h5 className="text-uppercase">
                    <strong>Philip J. Bromley</strong>
                  </h5>
                  <span className="inline-block">Chief Executive Officer</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
