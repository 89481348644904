import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";
import { PRODUCTS } from "components/pages/shared/products";

import RegularHeader from "components/layout/RegularHeader";

export default class ProductPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      direction: null
    };
  }

  handleSelect(index, event) {
    this.setState({ index: index, direction: event.direction });
  }

  changeIndex(index) {
    this.setState({ index: index });
  }

  render() {
    const prod_name = this.props.match.params.prod;

    if (prod_name in PRODUCTS) {
      const product = PRODUCTS[prod_name];

      return (
        <React.Fragment>
          <RegularHeader
            paths={[
              { name: "Home", path: "/" },
              { name: "Store", path: "/store" },
              { name: product.brand + " " + product.item, path: "" }
            ]}
          />

          <section>
            <Container>
              <Row>
                <Col md="5">
                  <Carousel
                    activeIndex={this.state.index}
                    direction={this.state.direction}
                    onSelect={(index, event) => this.handleSelect(index, event)}
                    controls={false}
                    indicators={false}
                  >
                    {product.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image}
                          alt={`${product.brand} + " " + ${product.item}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>

                  {product.images.map((image, index) => (
                    <Image
                      key={index}
                      className="d-inline store-product-thumb"
                      src={image}
                      alt={`${product.brand} + " " + ${product.item}`}
                      role="button"
                      onClick={event => this.changeIndex(index)}
                    />
                  ))}
                </Col>
                <Col md={{ span: 6, offset: 1 }} className="">
                  <h4 className="text-uppercase raleway">
                    {product.brand} - {product.item}
                  </h4>

                  <div className="price my-5">${product.price.toFixed(2)}</div>

                  <p>{product.description}</p>

                  {product.properties.map((property, index) => (
                    <div key={index}>
                      <strong>{property.name}:</strong>{" "}
                      {property.values.join(", ")}
                    </div>
                  ))}

                  <hr />
                  {product.form}
                </Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <RegularHeader
            paths={[
              { name: "Home", path: "/" },
              { name: "Store", path: "/store" },
              { name: "New Item", path: "" }
            ]}
          />

          <Container className="p-5 text-center">
            <h4>
              Vespene gas depleted... Go check our our store
              <Link to="/store">here</Link>, make good use of your Vespene gas.
            </h4>
          </Container>
        </React.Fragment>
      );
    }
  }
}
