import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import lightLogo from "img/light-logo.png";
import darkLogo from "img/dark-logo.png";

export default class NavBar extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired
  };

  render() {
    const { location } = this.props;

    return (
      <Navbar
        expand="md"
        bg={location === "/" ? "dark" : "white"}
        variant={location === "/" ? "dark" : "light"}
        className="text-uppercase"
        sticky="top"
      >
        <Navbar.Brand as={NavLink} exact to="/">
          <img
            alt="Virun Nutra-Biosciences"
            src={location === "/" ? lightLogo : darkLogo}
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} exact to="/">
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/start" className="glow">
              Start here
            </Nav.Link>

            <NavDropdown title="Our technologies" id="nav-techs">
              <NavDropdown.Item as={NavLink} to="/technologies/astaxh2o">
                AstaxH2o
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/technologies/dptechnology">
                DPTechnology
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/technologies/esolv">
                Esolv
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/technologies/omegah2o">
                OmegaH2o
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/technologies/probiferrin">
                Probiferrin
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Product Development" id="nav-dev">
              <NavDropdown.Item as={NavLink} to="/development/formulation">
                Formulation
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/development/applications">
                Applications
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/development/contract">
                Contract
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/development/copackaging">
                Co-Packaging
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/development/idea">
                Idea Sphere
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={NavLink} to="/store">
              Store
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
