import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";

import probiferrin from "img/probiferrin.jpg";

export default class FeaturedTechnology extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: (
        <React.Fragment>
          Probiferrin<sup>&trade;</sup>
        </React.Fragment>
      ),
      name: "Probiferrin",
      image: probiferrin,
      description:
        "So, you want to build a probiotic that actually stands up to its claims? You’ve come to the right place.",
      path: "/technology/probiferrin"
    };
  }

  render() {
    const { title, name, image, description, path } = this.state;

    return (
      <section className="bg-light">
        <Container>
          <Row>
            <Col>
              <h2 className="text-uppercase text-center">
                FEATURED TECHONOLOGY
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="text-center">
              <Image
                src={image}
                alt={name}
                className="shadow-lg p-3 mb-5 img-fluid"
              />
            </Col>
            <Col sm="6" className="pl-5 d-flex flex-wrap align-content-center">
              <h3 className="text-uppercase">{title}</h3>
              <p>{description}</p>
              <Link className="btn btn-lg btn-dark text-white px-5" to={path}>
                Learn More
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
