import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";

export default class SubscriptionForm extends Component {
  static propTypes = {
    status: PropTypes.string,
    message: PropTypes.any,
    onValidated: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      view: 0,
      validated: false,
      error: ""
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const email = form["email"];

    let current_error = "";

    email.setCustomValidity("Invalid");

    if (!email.value) {
      current_error = "Please enter a your email.";
    } else {
      if (
        !/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email.value)
      ) {
        current_error = "Please enter a valid email.";
      } else {
        email.setCustomValidity("");
      }
    }

    this.setState({
      validated: true,
      error: current_error
    });

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.props.onValidated({ EMAIL: email.value });
    }
  }

  render() {
    const { status, message } = this.props;
    const sending = status === "sending";

    let button_content = "Keep me informed";
    let error = this.state.error;
    let success = "";

    if (sending) {
      button_content = (
        <React.Fragment>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Subscribing...
        </React.Fragment>
      );
    }

    if (status === "error") {
      error = message;
    } else if (status === "sucess") {
      sucess = message;
    }

    return (
      <React.Fragment>
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={event => this.handleSubmit(event)}
        >
          <Row>
            <Col sm="6">
              <Form.Control
                className="border-white bg-primary text-white"
                type="email"
                name="email"
                placeholder="EMAIL ADDRESS"
                style={{ fontSize: 12 }}
              />
            </Col>
            <Col sm="6">
              <Button
                block
                variant="light"
                type="submit"
                className="text-primary font-weight-bold text-uppercase mt-sm-0 mt-1"
                style={{ fontSize: 12 }}
                disabled={sending}
              >
                {button_content}
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="my-2">
          <Col>
            {error !== "" && (
              <Alert variant="danger">
                <div dangerouslySetInnerHTML={{ __html: error }} />
              </Alert>
            )}
            {success !== "" && (
              <Alert variant="success">
                <div dangerouslySetInnerHTML={{ __html: success }} />
              </Alert>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
