import React, { Component } from "react";
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Image,
  ListGroup,
  Button
} from "react-bootstrap";
import Moment from "react-moment";

import ParallaxHeader from "components/layout/ParallaxHeader";
import { getDateRange } from "tools/date";
import { getEvents } from "services/events";
import { apiUrl } from "../../config.json";

export default class EventsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      looking: true,
      current_events: [],
      past_events: {}
    };
  }

  componentDidMount() {
    getEvents()
      .then(events => {
        const current_date = new Date();

        current_date.setHours(0, 0, 0, 0);

        let list_current_events = [];
        let list_past_events = {};

        events.forEach(event => {
          let event_date = new Date(event.end);

          if (event_date >= current_date) {
            list_current_events.push(event);
          } else {
            if (event_date.getFullYear() in list_past_events) {
              list_past_events[event_date.getFullYear()].push(event);
            } else {
              list_past_events[event_date.getFullYear()] = [];
              list_past_events[event_date.getFullYear()].push(event);
            }
          }
        });

        this.setState({
          looking: false,
          current_events: list_current_events,
          past_events: list_past_events
        });
      })
      .catch(err => {
        this.setState({
          looking: false
        });
      });
  }

  render() {
    const { looking, current_events, past_events } = this.state;

    const past_events_keys = Object.keys(past_events)
      .sort()
      .reverse();

    let events = <Col className="text-center">Loading events...</Col>;

    if (!looking) {
      if (current_events.length > 0) {
        events = current_events.map(event => (
          <Col key={event.id} md="4">
            <Image src={`${apiUrl}/${event.image}`} alt={event.title} fluid />
            <h4 className="mt-4 raleway">
              <strong>{event.title}</strong>
            </h4>
            <h5 className="raleway">
              <strong>
                {event.subtitle}
                <br />
                {getDateRange(event.start, event.end)}
                <br />
                <Moment format="h:mmA">{event.start}</Moment> -{" "}
                <Moment format="h:mmA">{event.end}</Moment>
              </strong>
              <br />
              {event.address}
            </h5>
            {event.link && (
              <Button
                href={event.link}
                variant="dark"
                className="px-4"
                target="new"
              >
                Learn More
              </Button>
            )}
          </Col>
        ));
      } else {
        events = (
          <Col className="text-center">
            We are currently taking a break from events.
          </Col>
        );
      }
    }

    return (
      <React.Fragment>
        <ParallaxHeader
          className={`parallax-nav events`}
          title="Virun Events"
          paths={[
            { name: "Home", path: "/" },
            { name: "Events", path: "/events" }
          ]}
        />

        <Jumbotron>
          <Container className="my-5">
            <Row className="my-5 text-center">
              <Col>
                <h2>Current Events</h2>
              </Col>
            </Row>
            <Row>{events}</Row>
          </Container>
        </Jumbotron>

        {past_events_keys.length > 0 && (
          <Container className="my-5">
            <Row className="my-5 text-center">
              <Col>
                <h2>Past Events</h2>
              </Col>
            </Row>
            <Row>
              {past_events_keys.map(year => (
                <Col key={year} md="4">
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h6 className="raleway">{year}</h6>
                    </ListGroup.Item>
                    {past_events[year].map(event => (
                      <ListGroup.Item key={event.id}>
                        <h6 className="raleway">
                          {event.title}
                          <br />
                          {getDateRange(event.start, event.end)}
                          <br />
                          {event.address}
                        </h6>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}
