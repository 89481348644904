import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import ventOne from "img/home-vent-one.png";

export default class Header extends Component {
  render() {
    return (
      <header className="d-flex flex-wrap align-content-center home-bg">
        <Container>
          <Row>
            <Col sm="8" lg="7">
              <img className="img-fluid" src={ventOne} alt="vent" />
            </Col>
            <Col xs="12">
              <h2 className="text-uppercase text-white">
                That no one else can achieve
              </h2>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}
