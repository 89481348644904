import React from "react";
import pdBlockFormulation from "img/pd-block-formulation.jpg";
import pdBlockApplications from "img/pd-block-applications.jpg";
import pdBlockContractManufacturing from "img/pd-block-contract-manufacturing.jpg";
import pdBlockCoPackaging from "img/pd-block-co-packaging.jpg";
import pdBlockIdeaSphere from "img/pd-block-idea-sphere.jpg";

export const DEVELOPMENTS = {
  formulation: {
    title: <React.Fragment>Formulation</React.Fragment>,
    image: pdBlockFormulation,
    contentTitle: (
      <React.Fragment>
        Our creative minds can frame and formulate your product.
      </React.Fragment>
    ),
    content: (
      <p>
        Virun creates finished, private-label products and concentrates
        utilizing our patented technologies. Our expertise lies in developing
        products which are new, different, and more likely to be successful on
        the store shelf. We specialize in formulating products using healthy
        ingredients consisting of FATS, NON-POLAR COMPOUNDS, PROTEINS and
        PROBIOTICS. For example, we can deliver up to 20,000mg of a single fat
        in as low as a 2oz shot, or 3,500mg in a tablespoon, all while
        delivering great flavor, stability and a satisfying experience (think of
        products similar to ice cream, chocolate milk or soda, but without the
        sugar and containing only good fats such as MCT (Medium Chain
        Triglycerides), EPA DHA Omega 3 or CLA (Conjugated Linoleic Acid).
        Incorporating such ingredients into foods, beverages, shots and treats
        as well as tasty dietary supplements are an uncharted opportunity and
        VIRUN is on the forefront with its patented technologies. Virun does not
        sell its own label, as we prefer to support the brands which invest in
        their products with us.
        <br />
        <br />
        Additionally, Virun’s team of innovative chemists and food scientists
        are highly skilled at finding solutions to your product development
        challenges. Whether you want to bring a product to market to attack a
        specific nutritional or functional issue, or re-launch a product
        currently on the shelf to make it fit in with the trend toward
        all-natural ingredients, Virun is your team. Contact Virun today.{" "}
        <a href="mailto:innovate.today@virun.com">innovate.today@virun.com</a>
      </p>
    )
  },
  applications: {
    title: <React.Fragment>Applications</React.Fragment>,
    image: pdBlockApplications,
    contentTitle: <React.Fragment>Choose your delivery!</React.Fragment>,
    content: (
      <p>
        Virun has a wide array of applications available for your product,
        including clear beverages, shots, smoothies and yogurts, pastes,
        gummies, and capsules, powdered coffee creamers, beers, stick-pack
        powders and more.
      </p>
    )
  },
  contract: {
    title: <React.Fragment>Contract Manufacturing</React.Fragment>,
    image: pdBlockContractManufacturing,
    contentTitle: (
      <React.Fragment>
        We can take your product from R&#38;D to pilot to scaled-up finished
        products.
      </React.Fragment>
    ),
    content: (
      <p>
        Virun’s state of the art manufacturing facility located in Pomona,
        California is fully equipped to manufacture your finished beverage,
        smoothie, shot, paste or powder product for you. Our processes include
        Research and Development, Complete Formulation, Flavor, Spray Dry
        Encapsulation and Agglomeration, Liquid Encapsulation, and Non-Polar
        Encapsulation to make ingredients water soluble. Additionally, Virun is
        certified cGMP, Star K Kosher, and Friends of the Sea (For our Omega
        products).
      </p>
    )
  },
  copackaging: {
    title: <React.Fragment>Co-Packaging</React.Fragment>,
    image: pdBlockCoPackaging,
    contentTitle: (
      <React.Fragment>
        Our state of the art facility can pack your product as well.
      </React.Fragment>
    ),
    content: (
      <p>
        So often, it is the packaging part of the process that causes difficulty
        in bringing a product to market. Virun has resolved the weak link in the
        chain by buying our own bottling line. We are capable of bottling 1 oz.
        shots up to 1 gallon multi-serving beverage and smoothie products, using
        aseptic, preservative-free processes, and hot or cold fill. Our line can
        take your product from the manufacturing state to bottling, capping and
        labeling, boxing and shipping.
      </p>
    )
  },
  idea: {
    title: <React.Fragment>Idea Sphere</React.Fragment>,
    image: pdBlockIdeaSphere,
    contentTitle: (
      <React.Fragment>Viruneers love a challenge! Bring it on.</React.Fragment>
    ),
    content: (
      <p>
        We take pride in our innovative talent and challenge you to bring us
        your most difficult project! Whether your product is difficult to
        flavor, hard to formulate, or you want everything in the bottle; or
        perhaps you have a great idea to meet a need in the market but aren’t
        sure where to begin, Virun can help you take your idea from lightbulb to
        market shelf.
      </p>
    )
  }
};
