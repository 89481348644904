import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Jumbotron } from "react-bootstrap";
import { TECHNOLOGIES } from "components/pages/shared/technologies";

import RegularHeader from "components/layout/RegularHeader";

export default class TechnologyPage extends Component {
  render() {
    const tech_name = this.props.match.params.tech;

    if (tech_name in TECHNOLOGIES) {
      const tech = TECHNOLOGIES[tech_name];

      return (
        <React.Fragment>
          <RegularHeader
            paths={[
              { name: "Home", path: "/" },
              { name: "Our Technologies", path: "/technologies" },
              { name: tech_name, path: "" }
            ]}
          />

          <Container fluid>
            {tech.sections.map(section => {
              let classNameOfImage = "p-0";

              if (section.id % 2) classNameOfImage = "p-0 order-lg-last";

              return (
                <Row key={section.id}>
                  <Col lg="6" className={classNameOfImage}>
                    <Image
                      src={section.image}
                      fluid
                      className="h-100"
                      alt={tech_name}
                    />
                  </Col>
                  <Col
                    lg="6"
                    className="d-flex flex-column align-self-center content-justify-center col-lg-6"
                    style={{ padding: "4em 10%" }}
                  >
                    <h3 className="mb-4">{section.title}</h3>
                    {section.content}
                  </Col>
                </Row>
              );
            })}
          </Container>

          <Jumbotron className="text-muted bg-light mb-0">
            <Container>
              <h4>References</h4>
              <div>{tech.references}</div>
            </Container>
          </Jumbotron>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Our Technologies", path: "/technologies" },
            { name: "New Technology", path: "" }
          ]}
        />

        <Container className="p-5 text-center">
          <h4>
            We are sorry but our technology just isn't there yet... Go check our
            technology <Link to="/technologies">here</Link>.
          </h4>
        </Container>
      </React.Fragment>
    );
  }
}
