import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Toast } from "react-bootstrap";

import Header from "components/pages/home/Header";
import FeaturedTechnology from "components/pages/home/FeaturedTechnology";
import TechnologyList from "components/pages/shared/TechnologyList";
import DevelopmentList from "components/pages/shared/DevelopmentList";
import Subscribe from "components/pages/home/Subscribe";

export default class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toast: true
    };
  }

  hideToast() {
    this.setState({ toast: false });
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <FeaturedTechnology />

        <section className="our-tech">
          <h2 className="text-center text-uppercase">Our technologies</h2>
        </section>

        <TechnologyList />

        <section>
          <h2 className="text-center text-uppercase mb-0">
            Product Development
          </h2>

          <DevelopmentList />
        </section>

        <Subscribe />

        <Toast
          show={this.state.toast}
          onClose={() => this.hideToast()}
          className="bg-dark text-light"
          style={{
            position: "fixed",
            bottom: 0,
            right: 20
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">Get Started</strong>
            <small>Tell us your idea</small>
          </Toast.Header>
          <Toast.Body>
            Let us help you turn your idea into a reality.
            <br />
            <Link to="/start">Click here to get started.</Link>
          </Toast.Body>
        </Toast>
      </React.Fragment>
    );
  }
}
