import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TECHNOLOGIES } from "components/pages/shared/technologies";
import { Container, Row, Col } from "react-bootstrap";

export default class TechnologyList extends Component {
  render() {
    const technology_list = TECHNOLOGIES;
    const technologies = Object.keys(technology_list);

    return (
      <React.Fragment>
        {technologies.map(tech => (
          <section key={tech} className={`parallax tech ${tech} text-light`}>
            <Container>
              <Row>
                <Col className="text-center">
                  <h2 className="text-uppercase mb-0 pb-2">
                    {technology_list[tech].name}
                  </h2>
                  <p>{technology_list[tech].summary}</p>
                  <Link
                    className="btn btn-lg btn-outline-light px-5"
                    to={`/technologies/${tech}`}
                  >
                    Learn More
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        ))}
      </React.Fragment>
    );
  }
}
