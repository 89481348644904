import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import RegularHeader from "components/layout/RegularHeader";

import internship from "img/internship.jpg";

export default class InternshipPage extends Component {
  render() {
    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Intership program", path: "" }
          ]}
        />

        <Container className="my-4 py-4">
          <Row>
            <Col>
              <Image src={internship} alt="Interns" fluid />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="pt-4">Virun Internship Program</h4>
              <hr className="mb-4" />
              <p>
                Ready to Innovate? Virun proudly offers internships to students
                studying chemistry, biochemistry and food science. The company
                has developed a formal internship program with California
                Polytechnic University in Pomona, the only university to
                establish a biochemistry degree in food science; and also takes
                student interns from other universities as well. Many of our
                interns, who show initiative (and a sense of humor), are asked
                to join the Virun team full-time once they graduate. Our company
                breeds innovation by finding teachable moments that empower
                people to think creatively to solve complex formulation and
                technology problems. Creativity, ingenuity, and diversity in
                thought and practice are what makes our team successful. For
                more information, please contact Virun at{" "}
                <a href="mailto:innovate.today@virun.com">
                  innovate.today@virun.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
