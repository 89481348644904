import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

export default class Product extends Component {
  render() {
    const { data } = this.props;

    if (data.page === "") {
      return (
        <React.Fragment>
          <Link to={`/product/${data.id}`}>
            <Image src={data.images[0]} fluid className="product-thumb" />
          </Link>
          <h5>
            {data.brand}
            <br />
            {data.item}
          </h5>
          <span className="display-block">${data.price.toFixed(2)}</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <a href={data.page} target="new">
            <Image src={data.images[0]} className="product-thumb" />
          </a>
          <h5>
            {data.brand}
            <br />
            {data.item}
          </h5>
          <span className="display-block">
            <a href={data.page} target="new">
              {data.page}
            </a>
          </span>
        </React.Fragment>
      );
    }
  }
}
