import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DEVELOPMENTS } from "components/pages/shared/developments";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";

export default class DevelopmentList extends Component {
  render() {
    const development_list = DEVELOPMENTS;
    const developments = Object.keys(development_list);

    return (
      <React.Fragment>
        <Container>
          <Row>
            {developments.map(dev => (
              <Col key={dev} md="4" sm="6" className="mt-4">
                <Accordion>
                  <Card>
                    <Link to={`/development/${dev}`}>
                      <Card.Img
                        variant="top"
                        src={development_list[dev].image}
                      />
                    </Link>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Button}
                      className="btn-block btn-light text-uppercase"
                      eventKey="0"
                    >
                      {development_list[dev].title}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="small">
                        {development_list[dev].contentTitle}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
