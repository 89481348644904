import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;

export function getEvents() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/events")
      .then(res => {
        if (res.status === 200) resolve(res.data.events);
        else resolve([]);
      })
      .catch(err => {
        reject(err);
      });
  });
}
