import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import ParallaxHeader from "components/layout/ParallaxHeader";

export default class SitemapPage extends Component {
  render() {
    return (
      <React.Fragment>
        <ParallaxHeader
          className={`parallax-nav sitemap`}
          title="Sitemap"
          paths={[
            { name: "Home", path: "/" },
            { name: "Sitemap", path: "" }
          ]}
        />

        <section>
          <Container>
            <h3 className="text-center">Virun Sitemap</h3>

            <section>
              <Row>
                <Col md="3">
                  <h5 className="text-uppercase">Technology</h5>

                  <hr />

                  <ul className="list-unstyled">
                    <li className="my-3">
                      <Link to="/technology/astaxh2o">AstaxH2O</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/technology/dptechnology">DPTechnology</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/technology/esolv">Esolv</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/technology/omegah2o">OmegaH2O</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/technology/probiferrin">Probiferrin</Link>
                    </li>
                  </ul>
                </Col>
                <Col md="3">
                  <h5 className="text-uppercase">Product development</h5>

                  <hr />

                  <ul className="list-unstyled">
                    <li className="my-3">
                      <Link to="/development/formulation">Formulation</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/development/applications">Applications</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/development/contract">
                        Contract Manufacturing
                      </Link>
                    </li>
                    <li className="my-3">
                      <Link to="/development/copackagin">Co-Packagin</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/development/idea">Idea Sphere</Link>
                    </li>
                  </ul>
                </Col>
                <Col md="3">
                  <h5 className="text-uppercase">Other</h5>

                  <hr />

                  <ul className="list-unstyled">
                    <li className="my-3">
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/store">Store</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/Events">Events</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/internship">Internship Program</Link>
                    </li>
                  </ul>
                </Col>
                <Col md="3">
                  <h5 className="text-uppercase">Legal</h5>

                  <hr />

                  <ul className="list-unstyled">
                    <li className="my-3">
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/terms">Terms of Use</Link>
                    </li>
                    <li className="my-3">
                      <Link to="/cookie">Cookie Policy</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </section>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
