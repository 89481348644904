import React, { Component } from "react";
import { Card, Form, Col } from "react-bootstrap";

export default class ContactInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: "",
      email: "",
      phone: "",
      zip: ""
    };
  }

  isValid(form) {
    const fullname = form["fullname"];
    const email = form["email"];
    const phone = form["phone"];
    const zip = form["zip"];

    let has_errors = false;
    let errors = {};

    fullname.setCustomValidity("Invalid");
    email.setCustomValidity("Invalid");
    phone.setCustomValidity("Invalid");
    zip.setCustomValidity("Invalid");

    if (!fullname.value) {
      errors["fullname"] = "Please enter your full name.";
      has_errors = true;
    } else {
      if (!/\S+\x20\S+/.test(fullname.value)) {
        errors["fullname"] = "Please enter your first and last name.";
        fullname.focus();
        has_errors = true;
      } else {
        fullname.setCustomValidity("");
      }
    }

    if (!email.value) {
      errors["email"] = "Please enter a your email.";
      has_errors = true;
    } else {
      if (
        !/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email.value)
      ) {
        errors["email"] = "Please enter a valid email.";
        has_errors = true;
      } else {
        email.setCustomValidity("");
      }
    }

    if (!phone.value) {
      errors["phone"] = "Please enter a your phone number.";
      has_errors = true;
    } else {
      if (
        !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone.value)
      ) {
        errors["phone"] = "Please enter a valid phone number.";
        has_errors = true;
      } else {
        phone.setCustomValidity("");
      }
    }

    if (!zip.value) {
      errors["zip"] = "Please enter a your zip code.";
      has_errors = true;
    } else {
      if (!/^[0-9]{5}$/.test(zip.value)) {
        errors["zip"] = "Please enter a valid zip code.";
        has_errors = true;
      } else {
        zip.setCustomValidity("");
      }
    }

    if (has_errors) {
      this.setState(errors);
    }

    return !has_errors;
  }

  render() {
    const { fullname, email, phone, zip } = this.state;

    return (
      <Card id="contact">
        <Card.Body>
          <Card.Title className="text-center pb-5">
            Contact Information
          </Card.Title>

          <Form.Row>
            <Form.Group as={Col} sm="4" xs="12" controlId="fullname">
              <Form.Label>Fullname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name"
                name="fullname"
                required
                autoFocus={true}
              />
              <Form.Control.Feedback type="invalid">
                {fullname}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="4" xs="12" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                required
              />
              <Form.Control.Feedback type="invalid">
                {email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="4" xs="12" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter phone number"
                name="phone"
                required
              />
              <Form.Control.Feedback type="invalid">
                {phone}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="address1">
            <Form.Label>Address</Form.Label>
            <Form.Control placeholder="Enter address" name="address" required />
            <Form.Control.Feedback type="invalid">
              Please enter your address.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} sm="4" xs="12" controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control placeholder="Enter city" name="city" required />
              <Form.Control.Feedback type="invalid">
                Please enter your city.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="5" xs="12" controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control placeholder="Enter state" name="state" required />
              <Form.Control.Feedback type="invalid">
                Please enter your state.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="3" xs="12" controlId="zip">
              <Form.Label>Zip</Form.Label>
              <Form.Control placeholder="Enter zip" name="zip" required />
              <Form.Control.Feedback type="invalid">
                {zip}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
    );
  }
}
