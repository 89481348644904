import React, { Component } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { PRODUCTS } from "components/pages/shared/products";

import ParallaxHeader from "components/layout/ParallaxHeader";
import Product from "components/pages/store/Product";

export default class StorePage extends Component {
  sort(type) {}

  render() {
    let products = PRODUCTS;

    return (
      <React.Fragment>
        <ParallaxHeader
          className={`parallax-nav store`}
          title="Virun Store"
          paths={[
            { name: "Home", path: "/" },
            { name: "Store", path: "" }
          ]}
        />

        <Container className="my-4 py-4">
          <Row className="mb-4 pb-4">
            <Col lg="2" md="4">
              <Dropdown>
                <Dropdown.Toggle
                  id="sort"
                  variant="light"
                  className="px-4 btn-block "
                >
                  Sort By
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={event => this.sort("high")}>
                    Highest Price
                  </Dropdown.Item>
                  <Dropdown.Item onClick={event => this.sort("low")}>
                    Lowest Price
                  </Dropdown.Item>
                  <Dropdown.Item onClick={event => this.sort("new")}>
                    Newest Item
                  </Dropdown.Item>
                  <Dropdown.Item onClick={event => this.sort("old")}>
                    Oldest Item
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col lg="10" md="8" className="text-right">
              <span className="text-muted">Displaying 3 of 3 results</span>
            </Col>
          </Row>
          <hr />
          <Row>
            {products.map(product => (
              <Col
                key={product.id}
                className="text-center"
                style={{ width: 180, overflow: "hidden" }}
              >
                <Product data={product} />
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
