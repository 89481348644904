import React, { Component } from "react";
import RegularHeader from "components/layout/RegularHeader";
import { Container, Form, Row, Col, Button, Modal } from "react-bootstrap";
import { offset } from "tools/position";
import { addInquiry } from "services/inquiries";

import ContactInformation from "components/pages/start/ContactInformation";
import ApplicationTypes from "components/pages/start/ApplicationTypes";
import Packaging from "components/pages/start/Packaging";
import Ingredients from "components/pages/start/Ingredients";
import Regulations from "components/pages/start/Regulations";

export default class StartPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 0,
      content: [],
      validated: false,
      result: {
        show: false,
        title: "",
        body: "",
        button: ""
      }
    };

    this.form = React.createRef();
    this.contact_information = React.createRef();
    this.application_types = React.createRef();
    this.packaging = React.createRef();
    this.ingredients = React.createRef();
    this.regulations = React.createRef();
  }

  componentDidMount() {
    this.view();
  }

  scroll() {
    const { view } = this.state;

    if (view === 1) {
      window.scrollTo({
        ...offset(document.getElementById("application")),
        behavior: "smooth"
      });
    } else if (view === 2) {
      window.scrollTo({
        ...offset(document.getElementById("packaging")),
        behavior: "smooth"
      });
    } else if (view === 3) {
      window.scrollTo({
        ...offset(document.getElementById("ingredients")),
        behavior: "smooth"
      });
    } else if (view === 4) {
      window.scrollTo({
        ...offset(document.getElementById("regulations")),
        behavior: "smooth"
      });
    }
  }

  isValid() {
    return (
      this.contact_information.current.isValid(this.form.current) &&
      this.application_types.current.isValid(this.form.current) &&
      this.packaging.current.isValid(this.form.current) &&
      this.ingredients.current.isValid(this.form.current) &&
      this.regulations.current.isValid(this.form.current)
    );
  }

  isSectionValid() {
    switch (this.state.view) {
      case 0:
        return this.contact_information.current.isValid(this.form.current);
      case 1:
        return this.application_types.current.isValid(this.form.current);
      case 2:
        return this.packaging.current.isValid(this.form.current);
      case 3:
        return this.ingredients.current.isValid(this.form.current);
      case 4:
        return this.regulations.current.isValid(this.form.current);
      default:
        return true;
    }
  }

  next(event) {
    if (this.isSectionValid()) {
      this.setState(
        state => {
          return { view: state.view + 1, validated: false };
        },
        () => {
          this.view(this.scroll);
        }
      );
    } else {
      this.setState({ validated: true });
    }
  }

  view(callback) {
    const { view } = this.state;

    let view_content = [];

    if (view >= 0) {
      view_content.push(
        <ContactInformation
          key="contact"
          currentView={view}
          ref={this.contact_information}
          fowardedRef={this.form}
        />
      );
    }
    if (view >= 1) {
      view_content.push(
        <ApplicationTypes
          key="applications"
          currentView={view}
          ref={this.application_types}
          fowardedRef={this.form}
        />
      );
    }
    if (view >= 2) {
      view_content.push(
        <Packaging
          key="packaging"
          currentView={view}
          ref={this.packaging}
          fowardedRef={this.form}
        />
      );
    }
    if (view >= 3) {
      view_content.push(
        <Ingredients
          key="ingredients"
          currentView={view}
          ref={this.ingredients}
          fowardedRef={this.form}
        />
      );
    }
    if (view > 3) {
      view_content.push(
        <React.Fragment key="regulations">
          <Regulations ref={this.regulations} fowardedRef={this.form} />

          <div className="my-5 d-flex justify-content-end">
            <Button type="submit" className="btn btn-primary px-5 py-2">
              Submit
            </Button>
          </div>
        </React.Fragment>
      );
    }

    if (view < 4) {
      view_content.push(
        <Row key="next" className="py-4">
          <Col className="text-right">
            <Button className="px-5" onClick={event => this.next(event)}>
              Next
            </Button>
          </Col>
        </Row>
      );
    }

    this.setState({ content: view_content }, callback);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ validated: true });

    if (this.isValid()) {
      addInquiry(new FormData(event.target))
        .then(res => {
          if (res) {
            this.setState({
              result: {
                show: true,
                title: "Thank you for your inquiry.",
                body:
                  "Thank you for your inquiry, we will get back to you as soon as we can.",
                button: "Return to main page"
              }
            });
          } else {
            this.setState({
              result: {
                show: true,
                title: "Sorry could not process your inquiry.",
                body:
                  "Sorry we could not process your inquiry, please try again later.",
                button: "Retry"
              }
            });
          }
        })
        .catch(err => {
          this.setState({
            result: {
              show: true,
              title: "Sorry could not process your inquiry.",
              body:
                "Sorry we could not process your inquiry, please try again later.",
              button: "Retry"
            }
          });
        });
    } else {
      this.setState({
        result: {
          show: true,
          title: "Invalid input.",
          body:
            "Sorry but it seems as there was something wrong with your input, please check the form.",
          button: "Retry"
        }
      });
    }
  }

  handleResult() {
    if (this.state.result.button === "Retry") {
      this.setState({
        result: {
          show: false,
          title: "",
          body: "",
          button: ""
        }
      });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    const { content, validated, result } = this.state;

    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Start Here", path: "/start" }
          ]}
        />

        <Container className="my-5">
          <Form
            noValidate
            validated={validated}
            onSubmit={event => this.handleSubmit(event)}
            ref={this.form}
          >
            {content}
          </Form>
        </Container>

        <Modal show={result.show} centered backdrop="static">
          <Modal.Header>
            <Modal.Title>{result.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{result.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={event => this.handleResult()}>
              {result.button}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
