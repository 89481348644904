import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Jumbotron, Container, Row, Col, Breadcrumb } from "react-bootstrap";

export default class RegularHeader extends Component {
  static propTypes = {
    paths: PropTypes.array.isRequired
  };

  render() {
    const { paths } = this.props;
    const last_path = paths.length - 1;

    return (
      <header>
        <Jumbotron className="mb-0">
          <Container>
            <Row className="text-uppercase">
              <Col lg="6">
                <h3>{paths[last_path].name}</h3>
              </Col>
              <Col lg="6">
                <Breadcrumb>
                  {paths.map((item, index) => {
                    if (index < last_path) {
                      return (
                        <li className="breadcrumb-item" key={index}>
                          <Link to={item.path}>{item.name}</Link>
                        </li>
                      );
                    } else
                      return (
                        <Breadcrumb.Item key={index} active>
                          {item.name}
                        </Breadcrumb.Item>
                      );
                  })}
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </header>
    );
  }
}
