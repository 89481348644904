import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
import { DEVELOPMENTS } from "components/pages/shared/developments";

import ParallaxHeader from "components/layout/ParallaxHeader";
import RegularHeader from "components/layout/RegularHeader";

export default class DevelopmentPage extends Component {
  render() {
    const dev_name = this.props.match.params.dev;

    if (dev_name in DEVELOPMENTS) {
      const dev = DEVELOPMENTS[dev_name];

      return (
        <React.Fragment>
          <ParallaxHeader
            className={`parallax-nav ${dev_name}`}
            title={dev.title}
            paths={[
              { name: "Home", path: "/" },
              { name: "Product Development", path: "/development" },
              { name: dev.title, path: "" }
            ]}
          />

          <section>
            <Container>
              <Row>
                <Col lg="3" className="mb-lg-0 mb-4">
                  <h5 className="text-uppercase">{dev.contentTitle}</h5>
                </Col>
                <Col lg="9">{dev.content}</Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Product Development", path: "/developments" },
            { name: "New Product Development", path: "" }
          ]}
        />

        <Container className="p-5 text-center">
          <h4>
            You must construct additional pylons... on the mean time check out
            our product development <Link to="/development">here</Link>.
          </h4>
        </Container>
      </React.Fragment>
    );
  }
}
