import React, { Component } from "react";
import RegularHeader from "components/layout/RegularHeader";
import TechnologyList from "components/pages/shared/TechnologyList";

export default class TechnologiesPage extends Component {
  render() {
    return (
      <React.Fragment>
        <RegularHeader
          paths={[
            { name: "Home", path: "/" },
            { name: "Our Technologies", path: "" }
          ]}
        />

        <TechnologyList />
      </React.Fragment>
    );
  }
}
